<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="suppliers"
      :fields="fields"
      responsive
    >
      <template #cell(name)="data">
        <router-link :to="handleRouting(data.item)">
          <span
            class="font-weight-bolder"
            :class="data.item.active_status ? 'text-primary' : 'text-danger'"
            @click="selectProvider(data.value)"
          >
            {{ data.value }}
          </span>
        </router-link>
      </template>
      <template #cell(address)="data">
        {{ data.value.formatted_address }}
      </template>
      <template #cell(approved_status)="data">
        <b-badge :variant="data.value | statusColor">
          {{ data.value | status }}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'editSupplier', params: { supplierId: data.item.id } }"
          >
            {{ $t('edit') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.approved_status !== 'pending'"
            @click="openConfirmationModal(data.item)"
          >
            <span
              :class="data.item.active_status ? 'text-danger' : 'text-success'"
            >
              {{
                data.item.active_status ? $t('deactivate') : $t('reactivate')
              }}
            </span>
          </b-dropdown-item>
          <!-- <b-dropdown-item
            v-b-modal="`activeStatusModal-${data.item.id}`"
          >
            {{ $t('delete') }}
          </b-dropdown-item> -->
        </b-dropdown>
        <!-- <b-modal
          :id="`activeStatusModal-${data.item.id}`"
          @ok="editSupplier({ id: data.item.id, active_status: false })"
        >
          <h2>
            ¿Estás seguro que deseas eliminar el proveedor "{{ data.item.name }}"?
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. At, laboriosam.
          </p>
        </b-modal> -->
      </template>
    </b-table>
    <confirmation-modal
      :id="'confirmDelete'"
      :variant="supplierToChange.active_status ? 'danger' : 'success'"
      @confirm="changeStatus(supplierToChange.id)"
    >
      <template v-slot:content>
        {{
          supplierToChange.active_status
            ? $t('deleteSupplierConfirmation', {
              supplier: supplierToChange.name,
            })
            : $t('reactivateSupplierConfirmation', {
              supplier: supplierToChange.name,
            })
        }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BTable } from 'bootstrap-vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    BTable,
    ConfirmationModal,
  },
  data() {
    return {
      supplierToChange: {},
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'contact_name',
          label: 'Representante',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'phone',
          label: this.$t('phone'),
        },
        {
          key: 'address',
          label: 'Ubicación',
        },
        {
          key: 'approved_status',
          label: this.$t('status'),
        },
        {
          key: 'actions',
          label: 'Herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers']),
  },
  methods: {
    ...mapMutations('suppliers', {
      selectProvider: 'SET_SELECTED_SUPPLIER',
    }),
    ...mapActions('suppliers', ['editSupplier', 'changeSupplierStatus']),
    handleRouting(item) {
      if (item.active_status) {
        return { name: 'branches', params: { id: item.id } }
      }
      return { name: 'editSupplier', params: { supplierId: item.id } }
    },
    openConfirmationModal(supplier) {
      this.supplierToChange = { ...supplier }
      this.$bvModal.show('confirmDelete')
    },
    changeStatus(supplierId) {
      this.changeSupplierStatus(supplierId).then(response => {
        this.supplier = response
        this.$emit('updateSuppliers')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('statusUpdated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>
