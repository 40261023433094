<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('suppliers') }}
        </h1>
        <b-button
          :to="{ name: 'registerSupplier' }"
          variant="primary"
        >
          {{ $t('register', { type: $t('provider') }) }}
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <card-statistics-group
          icon="CpuIcon"
          statistic="86%"
          :statistic-title="$t('stats')"
        />
      </b-col>
    </b-row> -->

    <b-row class="d-flex mb-2">
      <b-col
        sm="12"
        md="6"
        lg="3"
        class="mb-1"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
        >
          <b-form-select
            v-model="supplierStatus"
            @change="getSuppliers($event)"
          >
            <b-form-select-option :value="'active'">{{
              $t('suppliers')
            }}</b-form-select-option>
            <b-form-select-option :value="'awaiting_review'">{{
              $t('requests')
            }}</b-form-select-option>
            <b-form-select-option :value="'deleted'">{{
              $t('recycleBin')
            }}</b-form-select-option>
            <!-- <b-form-select-option :value="'inactive'">{{
              $t('inactive')
            }}</b-form-select-option> -->
          </b-form-select>
        </b-overlay>
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="6"
        class="mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
        >
          <b-form-input
            v-model="customerQuery"
            :placeholder="$t('search')"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <suppliers-table
          :items="suppliers"
          @updateSuppliers="getSuppliers(supplierStatus)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          :value="suppliersPagination.page"
          :total-rows="suppliersPagination.total_objects"
          :per-page="suppliersPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import CardStatisticsGroup from '@/components/cards/CardStatisticsGroup.vue'
import _ from 'underscore'
import SuppliersTable from '@/components/suppliers/SuppliersTable.vue'

export default {
  components: {
    // CardStatisticsGroup,
    SuppliersTable,
  },

  data() {
    return {
      supplierStatus: 'active',
      loading: false,
      customerQuery: '',
    }
  },

  computed: {
    ...mapGetters('suppliers', ['suppliers', 'suppliersPagination']),
  },

  watch: {
    customerQuery: _.debounce(function search() {
      this.getSuppliers(this.supplierStatus)
      // this.loading = false
    }, 500),
  },

  mounted() {
    this.fetchSuppliers({
      by_active_status: true,
      meta: { pagination: this.suppliersPagination },
    })
  },
  methods: {
    ...mapActions('suppliers', [
      'fetchSuppliers',
      'fetchSuppliersByApprovedStatus',
      'changeSupplierStatus',
    ]),
    ...mapMutations('suppliers', {
      setSuppliers: 'SET_SUPPLIERS',
      changePagination: 'CHANGE_SUPPLIERS_PAGINATION',
    }),

    async getSuppliers(status) {
      this.changePagination({ field: 'per_page', value: 10 })
      this.loading = true
      if (status) {
        switch (status) {
          case 'active':
            this.fetchSuppliers({
              by_name: this.customerQuery ? this.customerQuery : null,
              by_active_status: true,
              meta: { pagination: this.suppliersPagination },
            }).then(() => {
              this.loading = false
            })
            break
          case 'deleted':
            this.fetchSuppliers({
              by_name: this.customerQuery ? this.customerQuery : null,
              by_active_status: false,
              by_approved_status: 'approved',
              meta: { pagination: this.suppliersPagination },
            }).then(() => {
              this.loading = false
            })
            break
          case 'inactive':
            this.fetchSuppliers({
              by_name: this.customerQuery ? this.customerQuery : null,
              by_active_status: false,
              meta: { pagination: this.suppliersPagination },
            }).then(() => {
              this.loading = false
            })
            break
          case 'awaiting_review':
            try {
              const pending = await this.fetchSuppliersByApprovedStatus(
                { status: 'pending', name: this.customerQuery ? this.customerQuery : null },
              )
              const awaiting = await this.fetchSuppliersByApprovedStatus(
                { status: 'awaiting_review', name: this.customerQuery ? this.customerQuery : null },
              )
              const denied = await this.fetchSuppliersByApprovedStatus({ status: 'denied', name: this.customerQuery ? this.customerQuery : null })
              const s = [...pending.data, ...awaiting.data, ...denied.data]
              this.setSuppliers({
                data: s,
                meta: { pagination: {} },
              })
            } finally {
              this.loading = false
            }
            break
          default:
            break
        }
      }
    },

    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.getSuppliers(this.supplierStatus)
    },
  },
}
</script>

<style></style>
